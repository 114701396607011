import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import "./MenuItems.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import NewMenuItem from "./NewMenuItems.component";

export default function MenuItems() {
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [outletsData, setOutletsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Item_Name",
      text: "Item Name",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "Item_Price",
      text: "Item Price",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "Base_Price",
      text: "Base Price",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "Tax",
      text: "Tax",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "Selling_Price",
      text: "Selling Price",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "Tags",
      text: "Tags",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Is_available",
      text: "Is available",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "Cuisine.CuisineType",
      text: "Cuisine",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "Food_Type.FoodType",
      text: "Food Type",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      // align: (cell, row, rowIndex, colIndex) => {
      //   if (rowIndex % 2 === 0) return "right";
      //   return "left";
      // },
    },
    {
      dataField: "New_Item_Price",
      text: "New Item Price",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      text: "Item Type",
      sort: true,
      formatter: ItemTypeFormatter,
      align: "center",
      headerStyle: (column, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
  ];

  function ItemTypeFormatter(cell, row) {
    return (
      <>
        <div className="row" style={{ width: "50px", textAlign: "center" }}>
          {row.Is_Vegetarian && (
            <>
              <div className="col-md-4">
                <img
                  style={{ width: "20px" }}
                  src={require("../../Assets/Images/veg.png")}
                />
              </div>
            </>
          )}
          {row.Is_Non_Vegetarian && (
            <div className="col-md-4">
              <img
                style={{ width: "20px" }}
                src={require("../../Assets/Images/non-veg.png")}
              />
            </div>
          )}
        </div>
      </>
    );
  }
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="row" style={{ width: "200px" }}>
          {row.isActive && (
            <>
              <div className="col-md-4">
                <h3>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </h3>
              </div>
              <div className="col-md-4">
                <h3>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </h3>
              </div>
              <div className="col-md-4">
                <h3>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </h3>
              </div>
            </>
          )}
          {!row.isActive && (
            <div className="col-md-4">
              <h3>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </h3>
            </div>
          )}
        </div>
      </>
    );
  }
  useEffect(() => {
    getActiveOutlets();
  }, []);

  function OnOutletChange(selectedOutlet) {
    if (selectedOutlet === "Select Outlet") {
      setMenuItemsData([]);
    } else {
      loadMenuItems(selectedOutlet);
    }
  }

  function loadMenuItems(selectedOutlet) {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +`/MenuItems/getMenuItemsByOutlet/${selectedOutlet}`
      )
      .then((response) => {
        if (response.data.success) {
          setMenuItemsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  function getActiveOutlets() {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/Outlets/getOutletsDD")
      .then((response) => {
        if (response.data.success) {
          setOutletsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Menu Items List for </h4>
            <select
              class="form-select form-select-lg mb-3"
              aria-label=".form-select-lg example"
              onChange={(e) => {
                OnOutletChange(e.target.value);
              }}
            >
              <option selected>Select Outlet</option>
              {outletsData.map((i) => (
                <option value={i._id} key={i._id}>
                  {i.OutletName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
            <Button
              className="float-right"
              onClick={() => setIsOpen(true)}>
              Add Menu
            </Button>
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          {menuItemsData.length > 0 && (
            <CustomTable data={menuItemsData} columns={columns}></CustomTable>
          )}
        </div>
      </div>
      <div>
        <NewMenuItem
          isOpen={isOpen}
          returnAction={() => setIsOpen(false)}
        ></NewMenuItem>
      </div>
    </>
  );
}
