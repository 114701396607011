import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Vendor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
  faKey,
} from "@fortawesome/free-solid-svg-icons";

export default function Vendors() {
  const [vendorsData, setVendorsData] = useState([]);
  const [eachVendor, setEachVendor] = useState({});
  const [show, setShow] = useState(false);
  const [isNew, setIsNew] = useState(true);

  useEffect(() => {
    getAllVendors();
  }, []);
  function AddNewVendor() {
    setShow(true);
    setIsNew(true);
    setEachVendor({});
  }
  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setVendorsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    { dataField: "VendorName", text: "Vendor Name", sort: true },
    {
      dataField: "user_Id.emailID",
      text: "Email Address",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    { dataField: "MobileNumber", text: "Mobile Number" },
    { dataField: "PhoneNumber", text: "Phone Number" },
    { dataField: "PANNumber", text: "PAN Number" },
    {
      dataField: "Address",
      text: "Address",
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
  ];

  function onChange(e, controlName) {
    let eachVend = eachVendor;
    eachVend[controlName] = e.target.value;
    setEachVendor(eachVend);
  }
  function handleClose() {
    setShow(false);
    setIsNew(true);
    setEachVendor({});
  }

  function updatePassword(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/vendors/update/${Id}`, {
        Password: "abc@123",
      })
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function onVendorUpdate(row) {
    setShow(true);
    setIsNew(false);
    setEachVendor(row);
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="row" style={{ width: "200px" }}>
          {row.isActive && (
            <>
              <div className="col-md-3">
                <h3 onClick={() => onVendorUpdate(row)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </h3>
              </div>
              <div className="col-md-3">
                <h3 onClick={() => ActivateVendor(row._id, false)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </h3>
              </div>
              <div className="col-md-3">
                <h3 onClick={() => DeleteVendor(row._id)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </h3>
              </div>
              <div className="col-md-3">
                <h3 onClick={() => updatePassword(row._id)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faKey} />
                </h3>
              </div>
            </>
          )}
          {!row.isActive && (
            <div className="col-md-4">
              <h3 onClick={() => ActivateVendor(row._id, true)}>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </h3>
            </div>
          )}
        </div>
      </>
    );
  }

  function addVendor() {
    axios
      .post(process.env.REACT_APP_BASE_URL +"/vendors/create", eachVendor)
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
          handleClose();
        } else {
          alert(response.data.error);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function DeleteVendor(Id) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/vendors/update/${Id}`, {
        isDelete: true,
      })
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ActivateVendor(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/vendors/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllVendors();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div style={{ backgroundColor: "transparent", height:"100vh",overflowY:"scroll" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Vendors List</h4>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
            <Button
              className="float-right"
              onClick={() => {
                AddNewVendor({});
              }}
            >
              Add New Vendor
            </Button>
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          <CustomTable data={vendorsData} columns={columns}></CustomTable>
        </div>
      </div>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? "Add " : "Update "} Vendor</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formVendorName">
                <Form.Label>Vendor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Station Name"
                  defaultValue={eachVendor.VendorName}
                  onChange={(e) => onChange(e, "VendorName")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  disabled={isNew ? false : true}
                  placeholder="Enter Email Address"
                  defaultValue={eachVendor.EmailID}
                  onChange={(e) => onChange(e, "EmailID")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  disabled={isNew ? false : true}
                  placeholder="Enter Password"
                  defaultValue={eachVendor.Password}
                  onChange={(e) => onChange(e, "Password")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  defaultValue={eachVendor.MobileNumber}
                  onChange={(e) => onChange(e, "MobileNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  defaultValue={eachVendor.PhoneNumber}
                  onChange={(e) => onChange(e, "PhoneNumber")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>PAN Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PAN Number"
                  defaultValue={eachVendor.PANNumber}
                  onChange={(e) => onChange(e, "PANNumber")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter Address"
                  defaultValue={eachVendor.Address}
                  onChange={(e) => onChange(e, "Address")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={addVendor}>
              {isNew ? "Save" : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
