import React, { useEffect, useState } from "react";
import CustomTable from "../TableComponent/CustomTable.component";
import "./Outlet.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faCircleCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import NewOutlet from "./newOutlet.component";

export default function Outlets() {
  const [outletsData, setOutletsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Station_Id.StationName",
      text: "Station Name",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "250px", textAlign: "center" };
      },
    },
    {
      dataField: "ContactPerson",
      text: "Contact Person",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "EmailAddress",
      text: "Contact Email",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Address",
      text: "Address",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Order_Timing",
      text: "Order Timing",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Min_Order",
      text: "Min Order",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
   
    {
      dataField: "Delivery_Cost",
      text: "Delivery Cost",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Prepaid",
      text: "Prepaid",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "State",
      text: "State",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "City",
      text: "City",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
   
    {
      dataField: "Opening_Time",
      text: "Opening Time",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Closing_Time",
      text: "Closing Time",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    
    {
      dataField: "GST_NO",
      text: "GST NO",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "FSSAI_NO",
      text: "FSSAI NO",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "FSSAI_Valid_Upto",
      text: "FSSAI Valid Upto",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "Closing_Period",
      text: "Closing Period",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "WeeklyClosed",
      text: "Weekly Closed",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "VendorId.VendorName",
      text: "Vendor Name",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "VendorId.MobileNumber",
      text: "Vendor Mobile Number",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "VendorId.PANNumber",
      text: "Vendor PAN Number",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: ActiveFormatter,
      headerStyle: (column, colIndex) => {
        return { width: "200px", textAlign: "center" };
      },
    },
  ];
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function ActiveFormatter(cell, row) {
    return (
      <>
        <div className="row" style={{ width: "200px" }}>
          {row.isActive && (
            <>
              <div className="col-md-4">
                <h3>
                  <FontAwesomeIcon key={makeid(10)} icon={faEdit} />
                </h3>
              </div>
              <div className="col-md-4">
                <h3 onClick={() => ActivateOutlet(row._id, false)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faBan} />
                </h3>
              </div>
              <div className="col-md-4">
                <h3 onClick={() => DeleteOutlet(row._id)}>
                  <FontAwesomeIcon key={makeid(10)} icon={faTrash} />
                </h3>
              </div>
            </>
          )}
          {!row.isActive && (
            <div className="col-md-4">
              <h3 onClick={() => ActivateOutlet(row._id, true)}>
                <FontAwesomeIcon key={makeid(10)} icon={faCircleCheck} />
              </h3>
            </div>
          )}
        </div>
      </>
    );
  }
  useEffect(() => {
    getAllOutlets();
  }, []);

  function getAllOutlets() {
    axios
    .get(process.env.REACT_APP_BASE_URL + "/Outlets/getAllOutlets")
      .then((response) => {
        if (response.data.success) {
          setOutletsData(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function returnAction(reloadFlag){
    setIsOpen(false);
    if(reloadFlag)
    {
      getAllOutlets();
    }
  }

  function DeleteOutlet(Id) {
    alert("coming Soon");
    // axios
    //   .put(`http://localhost:8080/stations/update/${Id}`, {
    //     isDelete: true,
    //   })
    //   .then((response) => {
    //     if (response.data.success) {
    //       getAllStations();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("There was an error!", error);
    //   });
  }

  function ActivateOutlet(Id, flag) {
    axios
      .put(process.env.REACT_APP_BASE_URL +`/Outlets/update/${Id}`, {
        isActive: flag,
      })
      .then((response) => {
        if (response.data.success) {
          getAllOutlets();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  return (
    <>
      <div style={{ backgroundColor: "transparent", height:"100vh",overflowY:"scroll" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Outlets List</h4>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
            <Button className="float-right" onClick={() => setIsOpen(true)}>
              Add New Outlet
            </Button>
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          <CustomTable data={outletsData} columns={columns}></CustomTable>
        </div>
      </div>
      <div>
        <NewOutlet
          isOpen={isOpen}
          returnAction={(reloadFlag) => returnAction(reloadFlag)}
        ></NewOutlet>
      </div>
    </>
  );
}
