import React, {useEffect,useState} from "react";
import CustomTable from "../TableComponent/CustomTable.component";

export default function Orders() {
    const [ordersData,setOrdersData] = useState([]);

    useEffect(() => {
        setOrdersData([]);
      }, []);

    const columns = [
        {
          dataField: "_id",
          text: "Id",
          hidden: true,
        },
        {
          dataField: "Item_Name",
          text: "Name",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "Email",
          text: "Email",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "MobileNumber",
          text: "Mobile Number",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px", textAlign: "center" };
          },
        },
        {
          dataField: "Message",
          text: "Message",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "100px", textAlign: "center" };
          },
        },
        {
          dataField: "Date",
          text: "Date",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "150px", textAlign: "center" };
          },
        },
      ];
  return (
    <>
         <div style={{ backgroundColor: "transparent", height:"100vh",overflowY:"scroll" }}>
        <div className="row mainHeader">
          <div className="col-md-4">
            <h4 className="labeltextcolor">Orders</h4>
          </div>
          <div className="col-md-8" style={{ textAlign: "right" }}>
           
          </div>
        </div>
        <div className="col-md-12 mainHeader">
          <CustomTable data={ordersData} columns={columns}></CustomTable>
        </div>
      </div>
    </>
  );
}
