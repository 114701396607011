import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "./Outlet.css";
import TimePicker from 'react-bootstrap-time-picker';
import { cities } from "indian-cities-json";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CheckboxGroup from 'react-checkbox-group'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

const _ = require("lodash");



export default function NewOutlet(props) {
  const [outletInfo, setOutletInfo] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [loadStations, setLoadStations] = useState([]);
  const [loadVendors, setLoadVendors] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedNutriDocument, setSelectedNutriDocument] = useState(null);
  const [openingTime, setOpeningTime] = useState(null);
  const [closingTime, setClosingTime] = useState(null);
  const [weeklyClosed, setWeeklyClosed] = useState([])
  const [fssaiValidUpto, setFssaiValidUpto] = useState(null);
  useEffect(() => {
    setIsOpen(props.isOpen);
    setIsNew(true);
    getAllStations();
    getStatesList();
    getAllVendors();
  }, [props.isOpen]);

  const handleChange = e => {
    console.log(outletInfo);
    const { name, value } = e.target;
    setOutletInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  function getStatesList() {
    setStatesList(
      _.orderBy(
        _.uniqBy(cities, function (e) {
          return e.state;
        }), ['state'], ['asc']))
  }
  function getAllStations() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/stations/getAllStations")
      .then((response) => {
        if (response.data.success) {
          setLoadStations(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getAllVendors() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/vendors/getAllVendors")
      .then((response) => {
        if (response.data.success) {
          setLoadVendors(response.data.body);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function handleSubmit() {
    // let files = [];
    let OutletInfo = outletInfo;
    OutletInfo.ContactMobileNumber = phoneNumber;
    outletInfo.WeeklyClosed = weeklyClosed;
    let formData = new FormData();
    formData.append('outletInfo', JSON.stringify(outletInfo));
    if (selectedLogo != null)
      formData.append('file', selectedLogo);
    if (selectedNutriDocument != null)
      formData.append('file', selectedNutriDocument);

    axios
      .post(process.env.REACT_APP_BASE_URL + "/Outlets/create", formData)
      .then((response) => {
        if (response.data.success) {
          props.returnAction(true);
        }
        else
        {
          alert(response.data.error)
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const logoSelectedHandler = (event) => {
    var selectedFile = event.target.files[0];
    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      setSelectedLogo(event.target.files[0]);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      event.target.value = null;
    }

  }
  const selectedOpeningTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setOpeningTime(timeString);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Opening_Time']: timeString
    }));
  }


  const selectedClosingTime = (event) => {
    var date = new Date(0);
    date.setSeconds(event); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    console.log(timeString)
    setClosingTime(timeString);
    setOutletInfo(prevState => ({
      ...prevState,
      ['Closing_Time']: timeString
    }));
  }
  const documentSelectedHandler = (event) => {
    setSelectedNutriDocument(event.target.files[0]);
  }
  function onPrepaidChange(e) {
    let selectedValue = e.target.value;

    if (selectedValue !== 'Select Order Type') {
      setOutletInfo(prevState => ({
        ...prevState,
        ['Prepaid']: Boolean(selectedValue)
      }));
    }
    else {
      setOutletInfo(prevState => ({
        ...prevState,
        ['Prepaid']: null
      }));
    }
  }
  function onStateChange(e) {
    let selectedState = e.target.value;
    const { name, value } = e.target;
    setOutletInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
    setCitiesList(_.orderBy(_.filter(cities, function (o) { return o.state === selectedState; }), ['name'], ['asc']));
  }
  function handleClose() {
    props.returnAction(false);
  }

  function onFSSAIDateChange(event) {
    setFssaiValidUpto(event._d);
    setOutletInfo(prevState => ({
      ...prevState,
      ['FSSAI_Valid_Upto']: event._d
    }));
  }
  return (
    <>
      <div>
        {isOpen && (
          <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
              <Modal.Title>{isNew ? "Add " : "Update "} Outlet</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formBasicEmail">
                    <Form.Label>Station Name</Form.Label>
                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      onChange={handleChange}
                      name="Station_Id"
                    >
                      <option selected>Select Station</option>
                      {loadStations.map((i) => (
                        <option value={i._id} key={i._id}>
                          {i.StationName} - ({i.StationCode})
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formVendor">
                    <Form.Label>Select Vendor</Form.Label>
                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      onChange={handleChange}
                      name="VendorId"
                    >
                      <option selected>Select Vendor</option>
                      {loadVendors.map((i) => (
                        <option value={i._id} key={i._id}>
                          {i.VendorName}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formOutletName">
                    <Form.Label>Outlet Name</Form.Label>
                    <Form.Control type="text" onChange={handleChange}
                      name="OutletName" placeholder="Enter Outlet Name" />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formContactPerson">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" onChange={handleChange}
                      name="ContactPerson" placeholder="Contact Person Name" />
                  </Form.Group>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" onChange={handleChange}
                      name="EmailAddress" placeholder="Enter Email Address" />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formContactNo">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput defaultCountry="IN" value={phoneNumber}
                      onChange={setPhoneNumber} />
                  </Form.Group>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control as="textarea" onChange={handleChange}
                      name="Address" rows={5} />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="formMinOrder">
                    <Form.Label>Minimum Order</Form.Label>
                    <Form.Control type="number" onChange={handleChange}
                      name="Min_Order" placeholder="Min Order" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formOrderTiming">
                    <Form.Label>Order Timings</Form.Label>
                    <Form.Control type="number" onChange={handleChange}
                      name="Order_Timing" placeholder="Enter Order Timings" />
                  </Form.Group>
                </div>
              </div><div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formOrderType">
                    <Form.Label>Prepaid</Form.Label>
                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      onChange={onPrepaidChange}
                      name="Prepaid"
                    >
                      <option selected>Select Order Type</option>
                      <option key="true" value="true">True</option>
                      <option key="false" value="false">False</option>

                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formDeliveryCost">
                    <Form.Label>Delivery Cost</Form.Label>
                    <Form.Control type="number" onChange={handleChange}
                      name="Delivery_Cost" placeholder="Delivery Cost" />
                  </Form.Group>
                </div>
              </div><div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formOpeningTime">
                    <Form.Label>Opening Time</Form.Label>
                    <TimePicker start="00:00" end="23:59" step={30} value={openingTime} onChange={selectedOpeningTime} initialValue="00:00"
                      name="Opening_Time" />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formClosingTime">
                    <Form.Label>Closing Time</Form.Label>
                    <TimePicker start="00:00" end="23:59" step={30} value={closingTime} onChange={selectedClosingTime} initialValue="00:00"
                      name="Closing_Time" />
                  </Form.Group>
                </div>
              </div><div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formSelectState">
                    <Form.Label>Select Sate</Form.Label>
                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      onChange={(e) => onStateChange(e)}
                      name="State"
                    >
                      <option selected>Select State</option>
                      {statesList.map((i) => (
                        <option value={i.state} key={i.state}>
                          {i.state}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formSelectCity">
                    <Form.Label>Select City</Form.Label>
                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      onChange={handleChange}
                      name="City"
                    >
                      <option selected>Select State</option>
                      {citiesList.map((i) => (
                        <option value={i.name} key={i.name}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formFSSAINO">
                    <Form.Label>FSSAI NO:</Form.Label>
                    <Form.Control type="text" onChange={handleChange}
                      name="FSSAI_NO" placeholder="FSSAI NO" />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formFSSAIValid">
                    <Form.Label>FSSAI Valid Up to:</Form.Label>
                    <Datetime value={fssaiValidUpto} onChange={onFSSAIDateChange}></Datetime>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-6" controlId="formGSTNO">
                    <Form.Label>GST NO:</Form.Label>
                    <Form.Control type="text" onChange={handleChange}
                      name="GST_NO" placeholder="GST NO" />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">

                  <Form.Group className="mb-12" controlId="formSelectCity">
                    <Form.Label>Weekly Closed:</Form.Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <CheckboxGroup name="fruits" value={weeklyClosed} onChange={setWeeklyClosed}>
                      {(Checkbox) => (
                        <>
                          <label>
                            <Checkbox value="Mon" /> Monday
                          </label>
                          <label>
                            <Checkbox value="Tue" /> Tuesday
                          </label>
                          <label>
                            <Checkbox value="Wed" /> Wednesday
                          </label>
                          <label>
                            <Checkbox value="Thu" /> Thursday
                          </label>
                          <label>
                            <Checkbox value="Fri" /> Friday
                          </label>
                          <label>
                            <Checkbox value="Sat" /> Saturday
                          </label>
                          <label>
                            <Checkbox value="Sun" /> Sunday
                          </label>
                        </>
                      )}
                    </CheckboxGroup>

                  </Form.Group>
                </div>
              </div><div className="row">
                <div className="col-lg-6">
                  <Form.Group controlId="formFile" className="mb-3" accept=".png, .jpg, .jpeg">
                    <Form.Label>LOGO</Form.Label>
                    <Form.Control type="file" onChange={logoSelectedHandler} />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Nutritional Document</Form.Label>
                    <Form.Control type="file" onChange={documentSelectedHandler} accept="application/pdf,application/vnd.ms-excel" />
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                {isNew ? "Save" : "Update"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
}
