import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "./MenuItems.css";
import Papa from "papaparse";
import { toast } from 'react-toastify';


import CustomTable from "../TableComponent/CustomTable.component";
import _ from "lodash";



export default function NewOutlet(props) {
    const allowedExtensions = ["csv"];
    const [isNew, setIsNew] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [outletsData, setOutletsData] = useState([]);
    const [menuItemsData, setMenuItemsData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState(null);

    // It will store the file uploaded by the user
    //const [file, setFile] = useState("");


    useEffect(() => {
        setIsOpen(props.isOpen);
        setIsNew(true);
        getActiveOutlets();
    }, [props.isOpen]);

    function CleanState(){
        setSelectedOutlet(null);
        setColumns([]);
        setMenuItemsData([]);
        setOutletsData([]);
    }
    function getActiveOutlets() {
        axios
            .get(process.env.REACT_APP_BASE_URL +"/Outlets/getOutletsDD")
            .then((response) => {
                if (response.data.success) {
                    setOutletsData(response.data.body);
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    const handleChange = e => {
        setSelectedOutlet(e.target.value);
    };

    const csvUploadHandler = (e) => {


        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error("Wow so easy!")
                return;
            }
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { skipEmptyLines: true,header: true, dynamicTyping: true });
                const parsedData = csv?.data;
                setMenuItemsData(parsedData);
                const columns = Object.keys(parsedData[0]);
                let columnArray = [];
                _.each(columns, function (eachcolumn) {
                    columnArray.push(
                        {
                            dataField: eachcolumn,
                            text: eachcolumn,
                            sort: true,
                        },
                    )
                })
                setColumns(columnArray);
            };
            reader.readAsText(inputFile);
            // If input type is correct set the state
            //setFile(inputFile);

        }
    };

    const handleSubmit = () => {
        let tabledata = JSON.stringify({ "column": menuItemsData });
        tabledata = JSON.parse(tabledata)
        axios
            .post(process.env.REACT_APP_BASE_URL + `/MenuItems/createBulk/${selectedOutlet}`, tabledata)
            .then((response) => {
                if (response.data.success) {
                    CleanState();
                    toast.success("Wow so easy!" , {theme: "colored"});
                    props.returnAction();
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });

    };

    function handleClose() {
        CleanState();
        props.returnAction();
    }


    return (
        <>
            <div>
                {isOpen && (
                    <Modal
                        show={isOpen}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{isNew ? "Add " : "Update "} Outlet</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Form.Group className="mb-6" controlId="formBasicEmail">
                                        <Form.Label>Select Outlet</Form.Label>
                                        <select
                                            class="form-select form-select-lg mb-3"
                                            aria-label=".form-select-lg example"
                                            onChange={handleChange}
                                            name="Outlet_Id"
                                        >
                                            <option selected>Select Outlet</option>
                                            {outletsData.map((i) => (
                                                <option value={i._id} key={i._id}>
                                                    {i.OutletName}
                                                </option>
                                            ))}
                                        </select>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6">
                                    <Form.Group controlId="formFile" className="mb-3" accept=".png, .jpg, .jpeg">
                                        <Form.Label>Upload CSV</Form.Label>
                                        <Form.Control type="file" onChange={csvUploadHandler} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-md-12 mainHeader">
                                {menuItemsData.length > 0 && (
                                    <CustomTable data={menuItemsData} columns={columns}></CustomTable>
                                )}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                                {isNew ? "Save" : "Update"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </>
    );
}
