import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Components/Login/Login.component";
import PublicRoutes from "./Components/Routes/PublicRoutes";
import ProtectedRoutes from "./Components/Routes/ProtectedRoutes";
import Dashboard from "./Components/MainPage/Dashboard.component";
import Stations from "./Components/Stations/Stations.component";
import Vendors from "./Components/Vendor/Vendor.component";
import Outlets from "./Components/Outlets/Outlets.component";
import InnerContent from "./Components/MainPage/InnerContext.component";
import MenuItems from "./Components/MenuItems/MenuItems.component";
import Enquiry from "./Components/Enquiry/Enquiry.component";
import Users from "./Components/Users/Users.component";
import Refunds from "./Components/Refunds/Refunds.component";
import Orders from "./Components/Orders/Orders.component";

const MainRoutes = () => (
  <Routes>
    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="login" element={<PublicRoutes />}>
      <Route path="/login" element={<Login />} />
    </Route>

    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<InnerContent />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="stations" element={<Stations />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="Outlets" element={<Outlets />} />
        <Route path="MenuItems" element={<MenuItems />} />
        <Route path="users" element={<Users />} />
        <Route path="Enquiries" element={<Enquiry />} />
        <Route path="Refunds" element={<Refunds />} />
        <Route path="Orders" element={<Orders/>} />
      </Route>
    </Route>

    {/** Permission denied route */}
    {/* <Route path="/denied" element={<PermissionDenied />} /> */}
  </Routes>
);

export default MainRoutes;
